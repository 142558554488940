import { IButtonProps } from "."

export default function PillButton(props: IButtonProps) {
  const { children, className, ...HTMLButtonProps } = props

  return (
    <button
      {...HTMLButtonProps}
      className={
        "px-6 py-3 border border-blue7 rounded-lg text-blue7 text-base font-medium bg-white hover:bg-blue7 hover:text-white active:ring transition-colors" +
        " " +
        className
      }
    >
      {children}
    </button>
  )
}
